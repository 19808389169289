const APP_URL = process.env.NEXT_PUBLIC_WEBSITE_URL || 'http://localhost:3000';
const STRIPE_PUBLISHABLE_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || '';
const STRIPE_PRICING_TABLE_ID =
  process.env.NEXT_PUBLIC_STRIPE_PRICING_TABLE_ID || '';

const MAKE_CANCEL_SUBSCRIPTION_WEBHOOK_URL =
  process.env.MAKE_CANCEL_SUBSCRIPTION_WEBHOOK_URL || '';
const MAKE_WEBHOOK_URL = process.env.MAKE_WEBHOOK_URL || '';
const WEBHOOK_SECRET = process.env.WEBHOOK_SECRET || '';
const NEXT_PUBLIC_CLOUDFLARE_ACCOUNT_ID =
  process.env.NEXT_PUBLIC_CLOUDFLARE_ACCOUNT_ID || '';
const CLOUDFLARE_AUTH_TOKEN = process.env.CLOUDFLARE_AUTH_TOKEN || '';

const N8N_WEBHOOK_URL = process.env.N8N_WEBHOOK_URL;
const N8N_SECRET = process.env.N8N_SECRET;
const N8N_WELCOME_WEBHOOK_URL = process.env.N8N_WELCOME_WEBHOOK_URL;

const SESSION_UPDATE_COUNT = process.env.NEXT_PUBLIC_SESSION_UPDATE_COUNT || '';

const RETOOL_WORKFLOW_WEBHOOK_URL =
  process.env.RETOOL_WORKFLOW_WEBHOOK_URL || '';

const RETOOL_WORKFLOW_API_KEY = process.env.RETOOL_WORKFLOW_API_KEY || '';

//default azure keys
const azureOpenAIApiInstanceName =
  process.env.PRIVATE_AZURE_OPENAI_API_INSTANCE_NAME;
const azureOpenAIApiKey = process.env.PRIVATE_AZURE_OPENAI_API_KEY;
const azureOpenAIApiVersion = process.env.PRIVATE_AZURE_OPENAI_API_VERSION;
// azure keys for secondary embeddings (east-us)
const azureOpenAIEastUsApiInstanceName =
  process.env.PRIVATE_AZURE_EAST_US_OPENAI_API_INSTANCE_NAME;
const azureOpenAIEastUsApiKey =
  process.env.PRIVATE_AZURE_EAST_US_OPENAI_API_KEY;
// azure api version for ada-2
const azureAda2ApiVersion = process.env.PRIVATE_AZURE_ADA_2_OPENAI_API_VERSION;

export {
  APP_URL,
  STRIPE_PUBLISHABLE_KEY,
  STRIPE_PRICING_TABLE_ID,
  MAKE_CANCEL_SUBSCRIPTION_WEBHOOK_URL,
  MAKE_WEBHOOK_URL,
  WEBHOOK_SECRET,
  N8N_WEBHOOK_URL,
  N8N_SECRET,
  N8N_WELCOME_WEBHOOK_URL,
  NEXT_PUBLIC_CLOUDFLARE_ACCOUNT_ID,
  CLOUDFLARE_AUTH_TOKEN,
  SESSION_UPDATE_COUNT,
  RETOOL_WORKFLOW_WEBHOOK_URL,
  RETOOL_WORKFLOW_API_KEY,
  azureOpenAIApiInstanceName,
  azureOpenAIApiKey,
  azureOpenAIApiVersion,
  azureAda2ApiVersion,
  azureOpenAIEastUsApiInstanceName,
  azureOpenAIEastUsApiKey,
};
