import { useEffect, useState } from 'react';

import { inIframe } from '@/utils/element';

export function useRealScreenWidth() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!window) return;

    const isInIframe = inIframe();
    if (!isInIframe) return;

    const handleMessage = (event: MessageEvent) => {
      if (event.data?.key === 'parent-screen-width') {
        const width = event?.data?.value;
        if (!width) {
          setIsMobile(false);
          return;
        }

        if (width < 768) {
          setIsMobile(true);
          return;
        }

        setIsMobile(false);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return isMobile;
}
