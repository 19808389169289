'use client';

import { FC } from 'react';

import cn from 'classnames';
import { useColorPicker } from 'react-best-gradient-color-picker';

import { useMessengerContext } from '@/context/messengerContext';
import { addAlpha, getTextColor } from '@/utils/colors';

type FollowUpQuestionsProps = {
  className?: string;
  followUpQuestions: string[];
  userMessageBackgroundColor: string;
};

const FollowUpQuestions: FC<FollowUpQuestionsProps> = ({
  className,
  followUpQuestions,
  userMessageBackgroundColor,
}) => {
  const { handleAskQuestion } = useMessengerContext();

  const { valueToHex, isGradient } = useColorPicker(
    userMessageBackgroundColor,
    () => {},
  );
  const userBgHexColor = isGradient ? '#ebf2ff' : valueToHex();

  const bgColor = addAlpha(userBgHexColor, 0.1);
  const textColor = getTextColor(bgColor);
  const borderColor = addAlpha(textColor, 0.1);

  return (
    <div
      id="follow-up-wrapper"
      className={cn(
        'flex w-full flex-col items-end justify-center gap-[5px]',
        className,
      )}
    >
      {followUpQuestions?.map((item, index) => (
        <button
          key={index}
          onClick={() => handleAskQuestion(item, 'TEXT', true)}
          className="flex w-auto items-center justify-center whitespace-normal break-words rounded-[6px] px-[11px] py-[6px] text-left transition-transform hover:-translate-y-0.5 disabled:cursor-not-allowed disabled:opacity-50"
          style={{
            background: bgColor,
            color: textColor,
            border: `1px solid ${borderColor}`,
          }}
        >
          <span className="inline-block text-[14px] font-normal leading-5">
            {item}
          </span>
        </button>
      ))}
    </div>
  );
};

export default FollowUpQuestions;
