'use client';

import { ChangeEvent, useState } from 'react';
import { InputHTMLAttributes } from 'react';
import { FC } from 'react';
import { forwardRef } from 'react';

import cn from 'classnames';
import { FieldError } from 'react-hook-form';
import { toast } from 'react-toastify';

import { uploadImage } from '@/app/actions/uploadImage';
import PlusCircleIcon from '@/components/icons/plus-circle-icon.component';
import LoadingDots from '@/components/loading/loading-dots.components';
import ErrorToast from '@/components/toasts/error-toast.component';
import { blobToBase64 } from '@/utils/file';

interface MessageImageInputProps extends InputHTMLAttributes<HTMLInputElement> {
  chatbotId: string;
  chatId: string;
  name: string;
  error?: FieldError;
  handleUpload: (url: string) => void;
  labelClassName?: string;
}

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const MessageImageInput: FC<MessageImageInputProps> = forwardRef<
  HTMLInputElement,
  MessageImageInputProps
>(
  (
    {
      name,
      error,
      className,
      labelClassName,
      handleUpload,
      chatbotId,
      chatId = 'unknown',
      disabled,
      ...rest
    },
    ref,
  ) => {
    const [loading, setLoading] = useState(false);

    const handleOnChange = async (event: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      const image_list = event.target.files;

      if (image_list && image_list.length > 0) {
        const file = image_list[0];

        // Check file size
        if (file.size > MAX_FILE_SIZE_BYTES) {
          toast(
            <ErrorToast
              title="Error"
              detail={`File size exceeds the limit of ${MAX_FILE_SIZE_MB} MB.`}
            />,
          );
          setLoading(false);
          return;
        }

        try {
          const formData = new FormData();
          formData.append('file', file);

          const url = await uploadImage({
            formData,
            prefix: `${chatbotId}/chat/${chatId}/messages`,
          });

          handleUpload(url);
          event.target.value = '';
        } catch (error) {
          toast(
            <ErrorToast
              title="Error"
              detail={error?.message || 'Upload failed. Please try again.'}
            />,
          );
        }
      }

      setLoading(false);
    };

    return (
      <div className={cn(className)}>
        <label
          htmlFor={name}
          className={cn(
            'inline-flex h-[18px] w-[18px] cursor-pointer items-center justify-center transition-colors',
            {
              ['text-red-900']: !!error,
              '!cursor-not-allowed opacity-50': disabled,
              ['text-[#777777] hover:text-gray-900']: !error || !disabled,
            },
            labelClassName,
          )}
        >
          {loading ? (
            <LoadingDots size={18} />
          ) : (
            <PlusCircleIcon className="mt-[1px]" />
          )}

          <input
            id={name}
            name={name}
            type="file"
            className="sr-only"
            ref={ref}
            onChange={handleOnChange}
            disabled={loading || disabled}
            accept="image/jpeg,image/png,image/webp,image/jpg,image/heic" // Updated accept attribute
            {...rest}
          />
        </label>
      </div>
    );
  },
);

MessageImageInput.displayName = 'MessageImageInput';

export default MessageImageInput;
